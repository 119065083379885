export const timeArray = [
  {
    label: '12:00 - 12:30',
    value: '12:00 - 12:30',
  },
  {
    label: '12:30 - 13:00',
    value: '12:30 - 13:00',
  },
  {
    label: '13:00 - 13:30',
    value: '13:00 - 13:30',
  },
  {
    label: '13:30 - 14:00',
    value: '13:30 - 14:00',
  },
  {
    label: '14:00 - 14:30',
    value: '14:00 - 14:30',
  },
  {
    label: '14:30 - 15:00',
    value: '14:30 - 15:00',
  },
  {
    label: '15:00 - 15:30',
    value: '15:00 - 15:30',
  },
  {
    label: '15:30 - 16:00',
    value: '15:30 - 16:00',
  },
  {
    label: '16:00 - 16:30',
    value: '16:00 - 16:30',
  },
  {
    label: '16:30 - 17:00',
    value: '16:30 - 17:00',
  },
  {
    label: '17:00 - 17:30',
    value: '17:00 - 17:30',
  },
  {
    label: '17:30 - 18:00',
    value: '17:30 - 18:00',
  },
  {
    label: '18:00 - 18:30',
    value: '18:00 - 18:30',
  },
  {
    label: '18:30 - 19:00',
    value: '18:30 - 19:00',
  },
  {
    label: '19:00 - 19:30',
    value: '19:00 - 19:30',
  },
  {
    label: '19:30 - 20:00',
    value: '19:30 - 20:00',
  },
  {
    label: '20:00 - 20:30',
    value: '20:00 - 20:30',
  },
];
